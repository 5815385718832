import { Box, Container, Grid, Typography } from "@mui/material";
import Markdown from "react-markdown";
import Header from "../OrderPreview/Header";

const Terms = () => {
  return (
    <>
      <Box width="100%">
        <Container>
          <Grid container>
            <Grid item lg={12} gap="30px" mt="40px">
              <Typography variant="h1" textAlign="center" mb="30px">
                Terms & conditions
              </Typography>
              <Markdown>
                1. By checking this box, I affirm that I have read, understood
                and accept 1. ***Terms and Conditions, Privacy Policy** and **2.
                airline liability limitations***, confirmed the accuracy of
                flight itinerary, and verified the spelling of the name(s) of
                the passenger(s) to be matching passenger(s) passport(s) and 3.
                ***valid travel document(s)***. **GENERAL TERMS & CONDITIONS**
                For the purposes of present Terms & Conditions Tripoasia LLC,
                with the registered address at USA. 195 Montague St Brooklyn,
                NY  11201. These tickets are non-reroutable and
                non-transferable. Name changes on tickets are not allowed – only
                the original passenger (who was made reservation for) can use
                the ticket(s). If fare rules allow refunds and/or exchanges,
                they are permitted only at least 72 hours before the scheduled
                time of departure. In addition, a $150.00 Tripoasia fee per
                person will be charged to process any refund or exchange
                request. This fee will be in addition to the penalties charged
                directly by the airline and/or recalled by the airline from
                Tripoasia. Take special note that airline tickets and hotel
                accommodations and all service fees may not be refundable.
                No-Show tickets cannot be refunded, nor can they be exchanged.
                Unless the reservation is cancelled and the trip rescheduled
                prior to the original departure date (the date which reservation
                was made for the first time), the ticket is suspended (cannot be
                used anymore) and refunds are not possible. Please check your
                e-mail/Telegram App frequently for any schedule change
                notifications and verify the current flight information 72 hours
                prior to scheduled departure time. Failure to use any
                reservation (an arrangement the passenger makes to have a ticket
                for flight at a particular time in the future) will result in an
                automatic cancellation of all continuing and return flight
                reservations and suspension of the tickets. The ticket can be
                used only in the sequence it was issued. Out of sequence usage
                is not allowed. The recommended check-in time for all flights is
                minimum 3 hours before the scheduled departure time, even if you
                are traveling on a domestic flight to another airport to connect
                to your international flight. Federal law forbids the carriage
                of hazardous materials aboard aircraft in your luggage or on
                your person. **SUPPLIER RULES AND RESTRICTIONS** Additional
                terms and conditions will apply to your reservation and purchase
                of travel-related goods and services that you select from both
                airline and hotel suppliers. Please read these additional terms
                and conditions carefully on the airline or hotel or other
                accommodation providers websites. You agree to abide by the
                terms and conditions of purchase imposed by any supplier with
                whom you elect to deal, including, but not limited to, payment
                of all amounts when due and compliance with the supplier's rules
                and restrictions regarding availability and use of fares,
                products, or services. We reserve the right to cancel your
                booking if full payment is not received in a timely fashion.
                Some airline or hotel suppliers may require you to present a
                credit card or cash deposit upon check-in to cover additional
                expenses incurred during your travel. Such deposit is unrelated
                to any payment received by Tripoasia for your booking. You
                acknowledge that some third-party providers offering certain
                services and/or activities may require you to sign their
                liability waiver prior to participating in the service and/or
                activity they offer. You understand that any violation of any
                such supplier's rules and restrictions may result in
                cancellation of your reservation(s), in your being denied access
                to the applicable travel product or services, in your forfeiting
                any monies paid for such reservation(s), and/or in our debiting
                your account for any costs we incur as a result of such
                violation. **LIABILITY DISCLAIMER** The carriers, hotels and
                other suppliers providing travel or other services on this site
                are independent contractors and not agents or employees of the
                company or the company affiliates. The company and the company
                affiliates are not liable for the acts, errors, omissions,
                representations, warranties, breaches or negligence of any such
                suppliers or for any personal injuries, death, property damage,
                or other damages or expenses resulting therefrom. The company
                and the company affiliates have no liability and will make no
                refund in the event of any delay, cancellation, overbooking,
                strike, force majeure or other causes beyond their direct
                control, and they have no responsibility for any additional
                expense, omissions, delays, re-routing or acts of any government
                or authority. **COMPENSATION SERVICES** I (customer) consent to
                receive compensation services calls, text messages and/or emails
                from or on behalf of Tripoasia or its partners/affiliates at the
                provided telephone number and the email address if I am entitled
                for compensation under the Regulation (EC) No. 261/2004 of the
                European Parliament, and the Montreal Convention. Such calls,
                text messages and/or emails may take place if your flight is
                delayed or cancelled. If you would not want to receive these
                calls, text messages and/or emails, you may opt out. **SERVICE
                APPRECIATION** If the customer is happy with our agent's
                service, he/she may choose to add an extra amount to his/her
                purchase to appreciate it. The amount of appreciation is
                non-refundable. **VOUCHERS** We may issue vouchers to our
                customers from time to time as promotion or appreciation of
                their cooperation with us. Vouchers may also be part of a
                separate Travel Product. The customer may use a voucher to
                purchase a ticket by way of set-off of the voucher's amount
                against the ticket price. Only one voucher may be used to
                purchase a ticket. Other terms and conditions of a voucher,
                including its validity term and transferability, are set forth
                on the face of such voucher and will be available for review
                before its acceptance and receipt by the customer. **MINOR
                TRAVEL** A minor is anyone who is 17 years old or under. The
                rules for minor travel vary by airlines, but generally speaking:
                1) minors under 5 years old are not allowed to travel without
                adults 2) minors who are 5-14 years old may travel alone
                provided they receive a mandatory unaccompanied minor service
                from the airline and 3) in most cases for children between 15-17
                years old airline unaccompanied minor service is optional. We
                strongly advise you to verify the requirements for minor travel
                directly with the airlines in advance and in any case before
                purchasing a ticket for a minor with Tripoasia LLC is not
                responsible for denied boarding of a minor due to unmet
                requirements of the relevant airline. Please note the following:
                If a child requires unaccompanied minor service, additional
                airline fees may apply and this service must be booked directly
                with the airlines in advance of a minor travel. Unaccompanied
                min or service may not be available on all flights. For
                instance, depending on the child's age, some airlines may
                require nonstop flights for the unaccompanied minor. Guardian
                contact is usually required for unaccompanied minors. Anyone
                under the age of 17 who is flying alone on an international
                flight may be required to produce a signed letter of consent
                from a parent or responsible adult. When making a booking for a
                minor with Tripoasia LLC you warrant that: you possess full
                legal authority to create a binding legal obligation and
                purchase a ticket for such minor being his/her parent/legal
                guardian or otherwise you have verified the requirements for
                minor's travel with the relevant airline in advance and fully
                understand and agree to comply with them to allow travel of the
                minor with the relevant airline. These Terms of Use apply to
                booking of a travel for minor with Tripoasia LLC without any
                exceptions and subject to the above provisions. 2. Warsaw
                Convention **CONDITIONS OF CONTRACT AND OTHER IMPORTANT
                NOTICES** Please read the regulations below. Note: When you
                purchase this ticket, you agree to accept these rules and
                restrictions. **WARSAW CONVENTION AND MONTREAL CONVENTION**
                *PASSENGERS ON A JOURNEY INVOLVING AN ULTIMATE DESTINATION OR A
                STOP IN A COUNTRY OTHER THAN THE COUNTRY OF DEPARTURE ARE
                ADVISED THAT INTERNATIONAL TREATIES KNOWN AS THE MONTREAL
                CONVENTION, OR ITS PREDECESSOR, THE WARSAW CONVENTION, INCLUDING
                ITS AMENDMENTS (THE WARSAW CONVENTION SYSTEM), MAY APPLY TO THE
                ENTIRE JOURNEY, INCLUDING ANY PORTION THEREOF WITHIN A COUNTRY.
                FOR SUCH PASSENGERS, THE APPLICABLE TREATY, INCLUDING SPECIAL
                CONTRACTS OF CARRIAGE EMBODIED IN ANY APPLICABLE TARIFFS,
                GOVERNS AND MAY LIMIT THE LIABILITY OF THE CARRIER.* **NOTICE OF
                LIABILITY LIMITATIONS** **The Montreal Convention or the Warsaw
                Convention system may be applicable to your journey and these
                Conventions govern and may limit the liability of air carriers
                for death or bodily injury, for loss of or damage to baggage,
                and for delay.** Where the Montreal Convention applies, the
                limits of liability are as follows: - There are no financial
                limits in respect of death or bodily injury. - In respect of
                destruction, loss of, or damage or delay to baggage, 1,100
                Special Drawing Rights (approximately EUR 1,300; US $1,700) per
                passenger in most cases. - For damage occasioned by delay to
                your journey, 4,694 Special Drawing Rights (approximately EUR
                5,400; US $7,100) per passenger in most cases. EC Regulation No.
                889/2002 requires European Community carriers to apply the
                provisions of the Montreal Convention limits to all carriage by
                them of passengers and their baggage by air. Many non-European
                Community carriers have elected to do so in respect of the
                carriage of passengers and their baggage. Where the Warsaw
                Convention system applies, the following limits of liability may
                apply: - 16,600 Special Drawing Rights (approximately EUR
                20,000; US $20,000) in respect of death or bodily injury if the
                Hague Protocol to the Convention applies, or 8,300 Special
                Drawing Rights (approximately EUR 10,000; US $10,000) if only
                the Warsaw Convention applies. Many carriers have voluntarily
                waived these limits in their entirety, and US regulations
                require that, for journeys to, from or with an agreed stopping
                place in the US, the limit may not be less than US $75,000. - 17
                Special Drawing Rights (approximately EUR 20; US $20) per kg for
                loss of or damage or delay to checked baggage, and 332 Special
                Drawing Rights (approximately EUR 400; US $400) for unchecked
                baggage. - The carrier may also be liable for damage occasioned
                by delay. **Further information may be obtained from the carrier
                as to the limits applicable to your journey. If your journey
                involves carriage by different carriers, you should contact each
                carrier for information on the applicable limits of liability.**
                **Regardless of which Convention applies to your journey, you
                may benefit from a higher limit of liability for loss of, damage
                or delay to baggage by making at check-in a special declaration
                of the value of your baggage and paying any supplementary fee
                that may apply. Alternatively, if the value of your baggage
                exceeds the applicable limit of liability, you should fully
                insure it before you travel.** Time limit for action: Any action
                in court to claim damages must be brought within two years from
                the date of arrival of the aircraft, or from the date on which
                the aircraft ought to have arrived. Baggage claims: Written
                notice to the carrier must be made within 7 days of the receipt
                of checked baggage in the case of damage, and, in the case of
                delay, within 21 days from the date on which it was placed at
                the disposal of the passenger. Notice of Contract Terms
                Incorporated by Reference - Your contract of carriage with the
                carrier that provides you with carriage by air, whether
                international, domestic or a domestic portion of an
                international journey is subject to this notice; to any notice
                or receipt of the carrier; and to the carrier's individual terms
                and conditions (Conditions), related rules, regulations and
                policies (Regulations) and any applicable tariffs. - If your
                carriage is by more than one carrier, different Conditions,
                Regulations and any applicable tariffs may apply for each
                carrier. - The Conditions, Regulations and any applicable
                tariffs of each carrier are, by this notice, incorporated by
                reference into and made part of your contract of carriage. - The
                Conditions may include, but are not restricted to: - Conditions
                and limits on the carrier's liability for the bodily injury or
                death of passengers. - Conditions and limits on the carrier's
                liability for the loss of, damage to or delay of goods and
                baggage, including fragile or perishable goods. - Rules for
                declaring a higher value for baggage and for paying any
                supplementary fee that may apply. - Application of the carrier's
                Conditions and limits of liability to the acts of the carrier's
                agents, servants and representatives, including any person
                providing either equipment or services to the carrier. - Claims
                restrictions, including time limits by which passengers must
                file claims or bring actions against the carrier. - Rules about
                reconfirmations or reservations; check in times; the use,
                duration and validity of air transportation services; and the
                carrier's right to refuse carriage. - Rights of the carrier and
                limits on the carrier's liability for delay or failure to
                perform a service, including schedule changes, substitution of
                alternative carriers or aircraft and re-routing, and, when
                required by applicable law, the obligation of the carrier to
                notify passengers of the identity of the operating carrier or
                substituted aircraft. - Rights of the carrier to refuse carriage
                to passengers who fail to comply with applicable laws or who
                fail to present all necessary travel documents. - You can obtain
                more information about your contract of carriage, and find out
                how to request a copy, at places where transportation on the
                carrier is sold. Many carriers also have this information on
                their websites. When required by applicable law, you have the
                right to inspect the full text of your contract of carriage at
                the carrier's airport and sales offices, and upon request, to
                receive a copy by mail or other delivery service from each
                carrier free of charge. - If a carrier sells air transportation
                services or checks baggage specifying carriage on another
                carrier, it does so only as agent for the other carrier. YOU
                CANNOT TRAVEL IF YOU DO NOT HAVE ALL REQUIRED TRAVEL DOCUMENTS,
                SUCH AS PASSPORT AND VISA. GOVERNMENTS MAY REQUIRE YOUR CARRIER
                TO PROVIDE INFORMATION ON OR PERMIT ACCESS TO PASSENGER DATA.
                **DENIED BOARDING**: Airline flights may be overbooked, and
                there is a slight chance that a seat will not be available on a
                flight for which a person has a confirmed reservation. If the
                flight is overbooked, no one will be denied a seat until airline
                personnel first ask for volunteers willing to give up their
                reservation in exchange for compensation of the airline's
                choosing. If there are not enough volunteers, the airline will
                deny boarding to other persons in accordance with its particular
                boarding priority. With few exceptions, including failure to
                comply with the carrier's check-in deadline (carrier shall
                insert either "e;of _ minutes prior to each flight segment"e; or
                "e;(which are available upon request from the air carrier)"e;
                here), persons denied boarding involuntarily are entitled to
                compensation. The complete rules for the payment of compensation
                and each airline's boarding priorities are available at all
                airport ticket counters and boarding locations. Some airlines do
                not apply these consumer protections to travel from some foreign
                countries, although other consumer protections may be available.
                Check with your airline or your travel agent. **BAGGAGE:**
                Excess valuation may be declared on certain types of articles.
                Carriers may apply special rules for fragile, valuable, or
                perishable articles. Check with your carrier. **Checked
                Baggage:** Carriers may permit a free checked baggage allowance,
                which is set by the carrier and may differ by class, and/or
                route. Carriers may apply extra charges for checked baggage in
                excess of their permitted allowance. Check with your
                carrier. **Cabin (Unchecked)**Baggage: Carriers may permit a
                free cabin baggage allowance, which is set by the carrier and
                may differ by class, route, and/or aircraft type. It is
                recommended that cabin baggage be kept to a minimum. Check with
                your carrier. If more than one carrier is providing the
                transportation for your journey, each carrier may apply
                different rules on baggage (both checked and cabin). **SPECIAL
                BAGGAGE LIABILITY LIMITATIONS FOR US TRAVEL:** For domestic
                travel wholly between US points, federal rules require any limit
                on a carrier's baggage liability to be at least US$3400.00 per
                passenger, or the amount currently mandated by 14 CFR 254.5.
                **CHECK-IN TIMES.** The time shown on the itinerary/receipt is
                the departure time of the aircraft. Flight departure time is not
                the same as the time you must check-in or the time you must be
                available for boarding. Your carrier may refuse you carriage if
                you are late. Check-in times, as advised by your carrier, are
                the latest times at which passengers can be accepted for travel;
                boarding times, as advised by your carrier, are the latest times
                at which passengers must present themselves for boarding.
                **DANGEROUS GOODS (HAZARDOUS MATERIALS). For safety
                reasons,** dangerous goods must not be packed in checked or
                cabin (unchecked) baggage except as specifically permitted.
                Dangerous goods include but are not limited to: compressed
                gases, corrosives, explosives, flammable liquids and solids,
                radioactive materials, oxidising materials, poisons, infectious
                substances, and briefcases with installed alarm devices. **For
                security reasons**, other restrictions may apply. **Check with
                your carrier.** 3 Travel documents required for all tickets
                (e-tickets, paper tickets) include a Passport, which must be
                presented for all international flights and valid for at least
                six months beyond the period of the intended stay. Some
                countries may require a visa and/or a health card. It is
                passengers' responsibility to have all necessary travel
                documents in possession at check-in. Passengers will need to
                verify visa requirements for all stops, as some countries may
                require a transit visa. Please note, you may need transit visa
                even if you have USA Green Card. Travelers connecting between or
                transiting via airports within the European Union Schengen Zone
                may need a Schengen Entry visa. Traveling on a one-way ticket
                may be restricted. If you intend to travel on a one-way ticket,
                it is your responsibility to make sure you are eligible.
                Tripoasia agents do not advise passengers on visa requirements.
                Please contact the embassy or consulate of the country you are
                going to visit or transit through to get up-to-date
                requirements. In addition, passport and visa information may be
                obtained by contacting the Travel Advisory Section of the U.S.
                State Department at (202)-647-5225 or by visiting the State
                Department's Web site at www.state.gov . Non-U.S. passport
                holders should contact the embassies or consulates of their
                destination and transit countries to obtain entrance
                requirements. To obtain medical information, you may contact the
                Centers for Disease Control at (404)-332-4559 or visit the CDC's
                Web site at www.cdc.gov - DISCLAIMER Travel Information Manual
                Automatic (the “TIMATIC”) displayed on this website is being
                provided from external sources as a convenience, and is for
                informational purposes only. Tripoasia bears no responsibility
                with respect to the accuracy, legality or content of the TIMATIC
                or subsequent links. The TIMATIC is not legal advice, and may
                not represent the official opinion of Tripoasia. It does not
                constitute an endorsement or an approval by Tripoasia of any of
                the products, services or opinions of the corporation or
                organization or individual. The TIMATIC is provided “as is”
                without any warranty or condition of any kind, either expressed
                or implied, including, but not limited to, all implied
                warranties and conditions of merchantability, fitness for a
                particular purpose, title and non-infringement. In no event
                shall Tripoasia be liable for any direct, indirect, punitive,
                incidental, special or consequential damages arising out of or
                in any way connected with the use of the TIMATIC, or with the
                delay or inability to use the TIMATIC, or from your reliance on
                content obtained through the TIMATIC, or otherwise arising out
                of the access to the TIMATIC whether based on contract, tort,
                negligence or any other cause of action. The TIMATIC may provide
                links or references to other Internet sites or their parts
                maintained by third parties solely for your convenience.
                Tripoasia holds no responsibility for the content of such other
                sites, for hyperlinks to this TIMATIC or in which any part of
                this TIMATIC has been hyperlinked. As a result, Tripoasia shall
                not be liable for any damages or injury arising from or in
                connection with such content or hyperlinks. Tripoasia does not
                warrant that the operation of the TIMATIC will be uninterrupted
                or error-free. To the maximum extent permitted by law, Tripoasia
                excludes all liability to you for consequential loss arising out
                of any claim, including but not limited to indirect losses and
                loss of revenue, profit or anticipated profits, or destruction
                of data, whether or not due in whole or in part to the
                negligence or breach of duty, statutory or otherwise, of you.
                The information provided is supplied and updated regularly by
                the International Air Transport Association (IATA). In case of
                any apparent inaccuracies or incomplete information contained in
                the TIMATIC, please contact customerservice@indp.iata.org
              </Markdown>
              <Markdown></Markdown>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default Terms;
