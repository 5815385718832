import { Navigate, createBrowserRouter } from "react-router-dom";

import OrderPreview from "../pages/OrderPreview";
import Terms from "../pages/Terms";
import { useEffect } from "react";

const Home = () => {
  useEffect(() => {
    window.location.replace("https://tripoasia.com/");
  }, []);
  return <></>;
};

export const router = createBrowserRouter([
  {
    path: "/",
    children: [
      {
        path: ":id",
        element: <OrderPreview />
      },
      {
        path: "terms",
        element: <Terms />
      },
      {
        index: true,
        element: <Home />
      }
    ]
  },
  {
    path: "*",
    element: <Navigate to="/" />
  }
]);
