import Switch from "../../../components/Switch";
import styles from "./style.module.scss";
import transport from "../../../assets/icons/transport.svg";
import hotel from "../../../assets/icons/hotel.svg";

export default function Transport({ control }) {
  return (
    <div className={styles.items}>
      {/* <div className={styles.item}>
        <div className={styles.img}>
          <img src={hotel} />
          Hotel
        </div>
        <Switch control={control} name="with_hotel" />
      </div> */}
      <div className={styles.item}>
        <div className={styles.img}>
          <img src={transport} />
          Transport
        </div>
        <Switch control={control} name="with_transport" />
      </div>
    </div>
  );
}
