import { Box } from "@mui/material";
import TicketVariant from "../../../components/TicketVariant";
import Card from "../Card";
import styles from "./style.module.scss";

export default function FlightInfo({ flights, user }) {
  return (
    <Card title="Flights Information">
      <Box width="100%" flexDirection="column" display="flex" gap={2}>
        {flights?.map((item) => (
          <TicketVariant
            key={item.id}
            onlyRead
            data={JSON.parse(item.info)}
            isRoundTrip={item.type === "round_trip"}
          />
        ))}
      </Box>
      <div className={styles.operator}>
        <p>Operator</p>
        <p>
          {user?.first_name} {user?.last_name}
        </p>
      </div>
    </Card>
  );
}
