import { Dialog } from '@mui/material'
import { useState } from 'react'
import check from '../../../assets/icons/check.svg'
import styles from './style.module.scss'
import CloseIcon from '@mui/icons-material/Close'

export default function SuccessModal({ open, setOpen, externalOrderId }) {
  const handleClose = () => {
    setOpen(false)
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
      PaperProps={{
        sx: {
          borderRadius: '8px'
        }
      }}
    >
      <div className={styles.box}>
        <CloseIcon onClick={handleClose} className={styles.close} />
        <img src={check} />
        <p>
          Your application <br /> has been successfully completed!
        </p>
        <span>
          Application number {externalOrderId} <br /> completed successfully.
        </span>
      </div>
    </Dialog>
  )
}
