import { CircularProgress, Dialog } from "@mui/material";
import { useState } from "react";
import question from "../../../assets/icons/question.svg";
import styles from "./style.module.scss";
import CloseIcon from "@mui/icons-material/Close";

export default function ConfirmModal({
  open,
  setOpen,
  handleConfirm,
  isLoading
}) {
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{
        sx: {
          borderRadius: "8px"
        }
      }}
    >
      <div className={styles.box}>
        <CloseIcon onClick={handleClose} className={styles.close} />
        <img src={question} />
        <p>Are you sure you want to confirm this request?</p>
        <div className={styles.buttons}>
          <button onClick={handleClose}>No</button>
          <button onClick={handleConfirm} disabled={isLoading}>
            {isLoading ? "Loading..." : "Yes, confirm"}
          </button>
        </div>
      </div>
    </Dialog>
  );
}
