import styles from "./style.module.scss";
import logo from "../../../assets/icons/logo.png";
import { Box, Typography } from "@mui/material";

import PhoneIcon from "@mui/icons-material/Phone";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";

export default function Header({
  externalOrderId,
  agent_number,
  agent_fullname
}) {
  return (
    <header className={styles.header}>
      <div className={styles.nothing}></div>
      <img src={logo} alt="logo" />
      <Box display="flex" flexDirection="column">
        <Typography
          fontSize="14px"
          fontWeight={600}
          display="flex"
          alignItems="center"
          gap="10px"
        >
          <SupportAgentIcon color="primary" />
          {agent_fullname}
        </Typography>
        <Typography
          fontSize="14px"
          fontWeight={600}
          display="flex"
          alignItems="center"
          gap="10px"
        >
          <PhoneIcon color="primary" />
          <a href={`tel:+18007639229`}> +1 (800) 763 9229</a>
        </Typography>
      </Box>
    </header>
  );
}
