import VerifiedIcon from "@mui/icons-material/Verified";
import { Box, Typography } from "@mui/material";

const OrderConfirmed = () => {
  return (
    <Box
      width="100%"
      display="flex"
      justifyContent="center"
      height="100vh"
      alignItems="center"
    >
      <Box
        width="600px"
        display="flex"
        flexDirection="column"
        py={4}
        px={5}
        alignItems="center"
        borderRadius="8px"
        bgcolor="white"
      >
        <VerifiedIcon
          color="primary"
          style={{
            fontSize: 100
          }}
        />
        <Typography variant="h1" fontSize="24px" textAlign="center" mt={2}>
          Your application was confirmed, the link is no longer valid
        </Typography>
        <Typography variant="p" fontSize={16} textAlign="center" mt={2}>
          Please contact your agent in case of further assistance
        </Typography>
        <Typography variant="h3" mt={1}>
          <a
            href="tel: +18007639229"
            style={{
              textDecoration: "none",
              color: "var(--primary-color)"
            }}
          >
            +1 (800) 763 9229
          </a>
        </Typography>
      </Box>
    </Box>
  );
};

export default OrderConfirmed;
