import { configureStore } from "@reduxjs/toolkit";

import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore } from "redux-persist";
import thunk from "redux-thunk";
import authSlice from "./auth/authSlice";

const authPersistConfig = {
  key: "auth",
  storage
};

const authPersistedReducer = persistReducer(authPersistConfig, authSlice);

export const store = configureStore({
  reducer: {
    auth: authPersistedReducer
  },
  middleware: [thunk]
});

export const persistor = persistStore(store);
