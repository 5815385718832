import Card from "../Card";
import styles from "./style.module.scss";
import classNames from "classnames";
import { useWatch } from "react-hook-form";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import { useState } from "react";
import { Checkbox } from "@mui/material";
import pdf from "../Terms.pdf";

export default function PaymentInfo({
  payment,
  control,
  isConfirmed,
  confirmTip,
  insurance_price,
  externalOrderId,
  customerPayments,
}) {
  const [isReady, setIsReady] = useState(false);
  const [open, setOpen] = useState(false);
  const tipPrice = useWatch({
    control,
    name: "agent_tip",
  });
  const hasInsurance = useWatch({
    control,
    name: "has_insurance",
  });

  return (
    <Card title="Payment information" className={styles.box}>
      <div className={styles.info}>
        <div className={styles.element}>
          <p>Transaction ID</p>
          <p>{externalOrderId}</p>
        </div>
        {/* <div className={styles.element}>
          <p>Способ оплаты</p>
          <p>Наличные</p>
        </div> */}
        <div className={styles.element}>
          <p>Payment status</p>
          <p
            className={classNames(styles.status, {
              [styles.success]: payment?.status === "success",
            })}
          >
            {payment?.status === "pending" ? "Pending" : "Success"}
          </p>
        </div>
      </div>
      <div className={styles.check}>
        <h4>Check</h4>
        <div
          onClick={() => setOpen((prev) => !prev)}
          className={classNames(styles.value, styles.accordion)}
        >
          <p>Flight ticket</p>
          <p>
            ${payment?.price}
            <KeyboardArrowDownRoundedIcon
              style={{
                transform: `rotate(${!open ? 0 : 180}deg)`,
              }}
            />
          </p>
        </div>
        {open &&
          customerPayments?.map((data) => (
            <div className={styles.accordionInner}>
              <p>{data.fullname}</p>
              <p>${data.price}</p>
            </div>
          ))}

        {hasInsurance && (
          <div className={styles.value}>
            <p>Insurance</p>
            <p>Included</p>
          </div>
        )}
        <div className={styles.value}>
          <p>Token of Appreciation</p>
          <p>${tipPrice || 0}</p>
        </div>
        <div className={styles.total}>
          <p>Total </p>
          <p>${payment?.price + (tipPrice || 0)}</p>
        </div>
        <p className={styles.conditions}>
          <Checkbox
            checked={isReady && confirmTip}
            onChange={(e) => setIsReady(e.target.checked)}
          />{" "}
          By clicking the confirm application button, you agree to our{" "}
          <a href={pdf} target="_blank" rel="noreferrer">
            terms and conditions
          </a>
        </p>
        <button disabled={!isReady} type="submit">
          Confirm application
        </button>
      </div>
    </Card>
  );
}
