import { useMutation, useQuery } from "react-query";
import { request } from "../../utils/api";

const useOrderAPI = ({
  page,
  limit,
  isGetOrders = false,
  orderId,
  user_ids,
  start_date,
  end_date
}) => {
  const { data, isLoading, refetch } = useQuery(
    ["GET_ORDERS", page, limit, user_ids, start_date, end_date],
    () =>
      request.get("order", {
        params: {
          page,
          limit,
          user_ids,
          start_date,
          end_date
        }
      }),
    {
      enabled: !!isGetOrders
    }
  );

  const {
    data: order,
    isLoading: isLoadingCurrentOrder,
    refetch: refetchCurrentOrder
  } = useQuery(["GET_ORDER", orderId], () => request.get("order/" + orderId), {
    enabled: !!orderId
  });

  const createOrder = useMutation((data) => request.post("order", data));
  const updateOrder = useMutation((data) =>
    request.put("order/" + data.id, data)
  );
  const confirmOrder = useMutation((data) =>
    request.put("order/confirm/" + data.id, data)
  );
  const deleteOrder = useMutation((id) => request.delete("order/" + id));

  return {
    createOrder,
    updateOrder,
    data,
    isLoading,
    refetch,
    deleteOrder,
    order: order?.order,
    isLoadingCurrentOrder,
    refetchCurrentOrder,
    confirmOrder
  };
};

export default useOrderAPI;
