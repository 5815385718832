import styles from "./style.module.scss";
import plus from "../../../assets/icons/plus.svg";
import minus from "../../../assets/icons/minus.svg";
import Card from "../Card";
import { Box, Checkbox, Radio, RadioGroup } from "@mui/material";
import { useEffect, useState } from "react";
import classNames from "classnames";
import { NumericFormat } from "react-number-format";

const items = [
  {
    name: "🙂",
    priceFormat: "$10",
    price: 10
  },
  {
    name: "😀",
    priceFormat: "$20",
    price: 20
  },
  {
    name: "😃",
    priceFormat: "$30",
    price: 30
  },
  {
    name: "🤩",
    priceFormat: "$40",
    price: 40
  },
  {
    name: "😍",
    priceFormat: "$50",
    price: 50
  },
  {
    name: "🔥",
    priceFormat: "$100",
    price: 100
  }
];

export default function Tip({ setValue, confirmTip, setConfirmTip }) {
  const [price, setPrice] = useState(10);

  useEffect(() => {
    setValue("agent_tip", price);
  }, [price]);

  return (
    <Card title="Want to express your gratitude to your agent?">
      <>
        <div className={styles.rateAgent}>
          <p className={styles.title}>Rate the agent's service</p>
          <div className={styles.items}>
            {items.map((item) => (
              <div
                key={item.name}
                onClick={() => setPrice(item.price)}
                className={classNames(styles.item, {
                  [styles.active]: price === item.price
                })}
              >
                <p>{item.name}</p>
                <div className={styles.radio}>
                  {item.priceFormat} <Radio checked={price === item.price} />
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className={styles.tip}>
          <p className={styles.title}>
            You express your gratitude with the following amount of money
          </p>
          <Box display="flex" gap="20px" alignItems="flex-start" sx={{}}>
            <div className={styles.price}>
              <button
                type="button"
                onClick={() =>
                  price > 1 && setPrice((prev) => (prev > 0 ? prev - 5 : prev))
                }
              >
                <img src={minus} />
              </button>

              <NumericFormat
                value={price}
                displayType="text"
                prefix="$"
                onChange={(e) => {
                  setPrice(Number(e.target?.value?.replace("$", "")));
                }}
              />
              <button
                type="button"
                onClick={() => setPrice((prev) => prev + 5)}
              >
                <img src={plus} />
              </button>
            </div>

            <div className={styles.checkbox}>
              <Checkbox
                checked={confirmTip}
                onChange={() => setConfirmTip((prev) => !prev)}
              />
              <p>
                <strong>I agree</strong>, that this amount will be charged in
                addition to the cost of the airline ticket(s)
              </p>
            </div>
          </Box>
        </div>
      </>
    </Card>
  );
}
